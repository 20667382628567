import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import {
  backgroundColored,
  cta,
  ctaOutline,
  fontDark,
  fontMedium,
} from '@constants/colors';
import { laptop, tablet } from '@constants/media-queries';
import { exampleBooks } from '@constants/routes';
import { space } from '@constants/spaces';
import { themes } from '@constants/themes-data';
import Title from '@elements/Title';
import useMediaQuery from '@hooks/useMediaQuery';

import TwoColumn from './TwoColumn';

const Content = styled.div`
  color: ${fontDark};
  width: 100%;
  text-align: center;
  background-color: ${backgroundColored};
  > * {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    ${tablet} {
      max-width: 450px;
      justify-content: left;
      text-align: left;
    }
  }
  ${tablet} {
    text-align: left;
    > * {
      margin: 0;
    }
  }
`;

const ImgContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;
  align-items: center;
  svg {
    margin-left: ${space}px;
  }
  &:hover {
    color: ${ctaOutline};
  }
`;

const CircleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${tablet} {
    height: 350px;
  }
`;

const CircleBackground = styled.div`
  position: absolute;
  width: 330px;
  height: 330px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  ${tablet} {
    width: 410px;
    height: 410px;
  }
  ${laptop} {
    width: 480px;
    height: 480px;
  }
`;

const Themes = styled.div`
  display: flex;
  gap: ${space}px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${space * 3}px;
  max-width: 450px;
`;

const Theme = styled.div`
  cursor: default;
  align-items: center;
  gap: ${space}px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  color: ${fontMedium};
  font-weight: 500;
  padding: ${space}px ${space * 2}px;

  ${tablet} {
    &:hover {
      color: ${fontDark};
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
`;

const defaultImage =
  'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/books_triple_cover%20(1)_MKXJBpZfO.png?updatedAt=1716541080095&ik-s=2d7511d697747f7cbb7d61cb0424a865be6c0102';

const BookThemes = () => {
  const [displayImage, setDisplayImage] = useState(defaultImage);
  const { isLaptop, isMobile } = useMediaQuery();
  const timeoutRef = useRef<number | undefined>(undefined);

  const handleMouseEnter = (image: string) => {
    clearTimeout(timeoutRef.current);
    setDisplayImage(image || defaultImage);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setDisplayImage(defaultImage);
    }, 2000);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return (
    <TwoColumn
      alignment="center"
      wideColumnLeft={isLaptop}
      paddingTopMobile={0}
      leftColumn={
        <CircleContainer>
          <CircleBackground />
          <ImgContainer>
            <Link href={exampleBooks} passHref>
              <a>
                <Image
                  src={isMobile ? defaultImage : displayImage}
                  alt="Fotoboken thema's"
                  width={displayImage === defaultImage ? 600 : 370}
                  height={displayImage === defaultImage ? 600 : 370}
                  unoptimized={true}
                  layout="intrinsic"
                  loading="lazy"
                />
              </a>
            </Link>
          </ImgContainer>
        </CircleContainer>
      }
      rightColumn={
        <Content>
          <Title size="xl" as="h2" className="mb-24">
            Origineel cadeau voor ieder moment
          </Title>
          <Themes>
            {themes.map((item) => (
              <Link key={item.theme} href={exampleBooks} passHref>
                <Theme
                  onMouseEnter={
                    !isMobile ? () => handleMouseEnter(item.image) : undefined
                  }
                  onMouseLeave={!isMobile ? handleMouseLeave : undefined}
                >
                  {item.emoji && (
                    <Image
                      src={item.emoji}
                      alt={`${item.theme} thema`}
                      width={20}
                      height={20}
                      unoptimized={true}
                      loading="lazy"
                    />
                  )}
                  {item.theme}
                </Theme>
              </Link>
            ))}
          </Themes>
          <Link href={exampleBooks} passHref>
            <StyledLink>
              Voorbeelden bekijken
              <FontAwesomeIcon icon={faArrowRightLong} />
            </StyledLink>
          </Link>
        </Content>
      }
    />
  );
};

export default BookThemes;
